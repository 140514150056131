#loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexbox-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 0.5em;
  font-family: sans-serif;
  margin-top: 5em;
}

.blueHead {
  color: blue;
}

.left {
  margin-right: 6em;
  margin-left: 35px;
  width: 50%;
}

.right {
  width: 550px;
}

#main {
  width: 70%;
}

#qr {
  margin-left: 45%;
  margin-top: -342px;
}

#buttondiv {
  margin-left: 39.5%;
}

#acceptButton {
  background-color: rgb(255, 85, 0);
  width: 30%;
  margin-left: 16px;
}

/* #qrCodePlacementDiv {
margin-top: -21%;
margin-left: 40%;
} */

span {
  display: inline-block;
}
